import React, { Fragment } from "react";
import gql from "graphql-tag";
import { getToken } from "utils/authentication";
import Query from "utils/graphQL/Query";
import { mWarn } from "utils/logger";
import { storeDataToAsyncStorage } from "screens/common/utils";
import redirect from "./redirect";
import { getCookie } from "./session";

export const isUserCompletelyLoggedIn = (req) => {
  const authToken = getToken(req);
  const flowCompleted = getCookie("flow_completed", req);
  return authToken && flowCompleted === "ajflowcompletev1";
};

export const meInitialParams = `
  me {
    id
    first_name
    last_name
    gender
    date_of_birth
    mobile
    email
    email_verified
    mobile_verified
    referral_code
    alternate_mobile
    verified_details {
      mobile
      verified_by_candidate
    }
    whatsapp_subscribed
    whatsapp_chatbot_subscribed
    photo
    stakeholder {
      ... on Candidate {
        id
        modified
        created
        resume
        profile_completion
        alternate_mobile
        expectation {
          notice_period
          preferred_cities {
            id
            name
          }
        }
        salary {
          id
          current_salary
          current_salary_format
          expected_salary
          expected_salary_format
        }
        total_experience
        is_experienced
        educations {
          id
          proficiency_level
        }
        address {
          id
          place {
            place_id
            location
          }
          locality {
            id
            name
            city {
              id
              name
            }
          }
        }
        functional_areas {
          id
          name
        }
        fa_experience {
          id
          functional_area {
            id
            name
            image
          }
          experience
        }
        generated_resume_urls{
          image
          pdf
        }
      }
    }
  }
`;

export const INITIAL_LOGGED_QUERY = gql`
  query InitialLoggedInQuery($name_list: [String!]) {
    ${meInitialParams}
    sources(name__in: $name_list) {
      id
      name
      type
    }
  }
`;

export const SOURCES_LIST = ["Call HR", "Walk In Job"];

const convertToObject = (sources) =>
  sources?.reduce((obj, value) => {
    // eslint-disable-next-line no-param-reassign
    obj[value.name] = value;
    return obj;
  }, {});
const EMPTY_OBJ = {};
const withAuthToken = (WrappedComponent) => {
  class WithAuthToken extends React.PureComponent {
    static async getInitialProps(ctx) {
      let innerProps = {};

      const authToken = getToken(ctx.req);
      const isCompletelyLoggedIn = isUserCompletelyLoggedIn(ctx.req);
      const hasCandidateZone = ctx.asPath.startsWith("/candidate-zone/");

      if (!hasCandidateZone && isCompletelyLoggedIn) {
        //matches path ENDS with "/" and "/?ln=df|en|fr"
        const candidateZoneRoute = /((^\/{1}\B)(\?ln=\w+)?)$/.test(ctx.asPath) // ctx.asPath === "/"
          ? `/candidate-zone/dashboard/`
          : `/candidate-zone${ctx.asPath}`;
        redirect(ctx.res)(candidateZoneRoute);
      } else if (hasCandidateZone && !isCompletelyLoggedIn) {
        // Trying to access a protected route while logged out
        const registrationRoute = `/candidate/register/?next=${encodeURIComponent(
          ctx.asPath
        )}`;
        redirect(ctx.res)(registrationRoute);
      }

      if (WrappedComponent.getInitialProps !== undefined) {
        innerProps = await WrappedComponent.getInitialProps(ctx);
      }

      const authTokenObject = isCompletelyLoggedIn ? { authToken } : {};

      return { ...innerProps, ...authTokenObject };
    }

    saveMobileVerifiedState = async (mobileVerified) => {
      if (process.browser) {
        try {
          await storeDataToAsyncStorage("mobileVerified", mobileVerified);
        } catch (error) {
          mWarn("error while setting mobile verified ", error);
        }
      }
    };

    render() {
      const { authToken } = this.props;
      return (
        <Fragment>
          {authToken ? (
            <Query
              query={INITIAL_LOGGED_QUERY}
              variables={{ name_list: SOURCES_LIST }}
              fetchPolicy="network-only"
              nextFetchPolicy="cache-first"
            >
              {(result) => {
                // result is undefined and causing error when doing destructuring
                const { me, sources } = result || EMPTY_OBJ;
                if (me) {
                  this.saveMobileVerifiedState(me.mobile_verified);
                } else {
                  console.warn(
                    `[withAuthToken] User detail not available result:${result}\n props:${this.props}`
                  );
                }
                return (
                  <WrappedComponent
                    {...this.props}
                    user={me}
                    sources={convertToObject(sources)}
                  />
                );
              }}
            </Query>
          ) : (
            <WrappedComponent {...this.props} />
          )}
        </Fragment>
      );
    }
  }
  WithAuthToken.displayName = `WithAuthToken(${getDisplayName(
    WrappedComponent
  )})`;
  return WithAuthToken;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withAuthToken;
