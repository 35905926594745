import PropTypes from "prop-types";
import React from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import Colors from "../../config/colors";

const SLoader = ({ containerStyle, ...restProps }) => (
  <ActivityIndicator
    style={[styles.container, containerStyle]}
    {...restProps}
  />
);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center"
  }
});

SLoader.defaultProps = {
  color: Colors.BlueGrey[12],
  size: "large"
};

SLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(["small", "large"]),
    PropTypes.number
  ])
};

export default SLoader;
