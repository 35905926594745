import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Query } from "@apollo/client/react/components";
import SLoader from "styled42/components/SLoader";
import SText from "styled42/components/SText";
import { mLog } from "utils/logger";
import { getGraphQLErrorCode } from "utils/graphQL";

const CustomQuery = ({
  children,
  loaderContainerStyle,
  showLoading = true,
  customError = false,
  loadingIndicator = <SLoader />,
  ...restProps
}) => (
  <Query {...restProps}>
    {({ loading, error, data, fetchMore, client, stopPolling }) => {
      if (loading && !data) {
        if (showLoading) {
          return (
            <View style={[styles.loaderContainer, loaderContainerStyle]}>
              {loadingIndicator}
            </View>
          );
        }
        return null;
      }
      if (customError) {
        return children(error, data, fetchMore, client, stopPolling);
      }
      if (error && !data) {
        const errorCode = getGraphQLErrorCode(error);
        const { query } = restProps;
        const queryName =
          query &&
          query.definitions &&
          query.definitions[0] &&
          query.definitions[0].name &&
          query.definitions[0].name.value;
        // handling special case for similarJobs query,
        // if OBJECT_NOT_FOUND error, return empty array in findSimilarJobs data
        if (errorCode === "OBJECT_NOT_FOUND" && queryName === "SimilarJobs") {
          return children(
            { findSimilarJobs: [], ...data },
            fetchMore,
            client,
            stopPolling
          );
        }
        mLog(`Error!: ${error}`);
        return (
          <View style={styles.errorContainer}>
            <SText>Some error has occured</SText>
          </View>
        );
      }
      return children(data, fetchMore, client, stopPolling);
    }}
  </Query>
);

const styles = StyleSheet.create({
  errorContainer: {
    margin: 15,
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  loaderContainer: {
    margin: 15,
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});

export default CustomQuery;
